import type { ProductPriceResponse } from "./types";

export const retrieveProductPricing = async (
  sku: string
): Promise<ProductPriceResponse> => {
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/products/${sku}/prices`,
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_TESTER_TOKEN}`
      }
    }
    // TODO - Add session token/validation here, or add as function parameter to be passed in?
  );

  if (!response.ok) {
    // TODO
    const errorData = await response.json();
    throw new Error(JSON.stringify({
      code: errorData.Code,
      message: errorData.Message,
      friendlyMessage: "",
      status: response.status
    }));
  }

  // throw new Error('some error with pricing')
  const data: ProductPriceResponse = await response.json();
  return data;
};
