import type { InventoryByPlant } from "./types";

export const retrieveBulkInventory= async (skus: string[]): Promise<
  Record<string, InventoryByPlant>
> => {
  // TODO - Add session token/validation here, or add as function parameter to be passed in?
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/inventory`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // TODO
        // Authorization: `Bearer ${session.token}`,
      },
      body: JSON.stringify(skus),
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error();
  }

  const data: Record<string, InventoryByPlant> = await response.json();
  return data;
};
