import type { Product, ProductFilters, PartialProduct } from "./types";
import { getParamStringFromObject } from "~/utils/helpers";

export const retrieveProduct = async (
  sku: string,
  filters: ProductFilters = {}
): Promise<Product> => {
  const params = getParamStringFromObject(filters);
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/products/${sku}${params}`,
  );

  if (!response.ok) {
    // TODO
    throw new Error();
  }

  const data: Product = await response.json();
  return data;
};
